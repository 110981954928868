$black: #000000;
$white: #ffffff;

// Theme
$primary: #004f9f;
$primary--xlight: #ddf1f8;
$primary--light: #00adea;
$primary--dark: #003c77;

$secondary: #ff326e;
$secondary--light: #f86262;
$secondary--xlight: #fff5f8;
$secondary--dark: #cf3966;
$secondary--xdark: #7a1e3a;

$tertiary: #fa8724;
$tertiary--light: #ffe176;
$tertiary--xlight: #fff8dd;
$tertiary--dark: #db6d0d;

$quaternary: #5cc858;
$quaternary--light: #87e483;
$quaternary--xlight: #e8fff3;
$quaternary--dark: #4ca149;

$quinary: #b4bacc;
$quinary--light: #f8faff;
$quinary--dark: #a0aac7;
$quinary--xdark: #747c91;

$senary: #7239ea;
$senary--light: #9667fd;
$senary--dark: #4e289e;
$senary--xdark: #372266;

#btn-flotante {
    // font-size: 16px; /* Cambiar el tamaño de la tipografia */
    // text-transform: uppercase; /* Texto en mayusculas */
    // font-weight: bold; /* Fuente en negrita o bold */
    // color: #ffffff; /* Color del texto */
    // border-radius: 5px; /* Borde del boton */
    // letter-spacing: 2px; /* Espacio entre letras */
    // background-color: #E91E63; /* Color de fondo */
    // padding: 18px 30px; /* Relleno del boton */
    position: fixed;
    bottom: 40px;
    right: 40px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

#btn-flotante:hover {
    // background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
    #btn-flotante {
        font-size: 14px;
        padding: 12px 20px;
        bottom: 20px;
        right: 20px;
    }
}


.contract-buttom-status {
    border: 1px solid !important;
    margin-right: 15px !important;
}

.MuiList-root {
    width: 100% !important;
}

.container {
    &--relative {
        position: relative;
    }

    &--absolute {
        position: absolute;
    }

    &--fixed {
        position: fixed;
    }
}

.title {
    &--head {
        font-size: 1.8rem;
        font-weight: 600;
    }

    &--sub-head {
        font-size: 1rem;
    }
}

.text-align {
    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--justify {
        text-align: justify;
    }
}

.width {
    &--full {
        width: 100%;
    }

    &--half {
        width: 50%;
    }

    &--third {
        width: 33.33%;
    }

    &--quarter {
        width: 25%;
    }

    &--fifth {
        width: 20%;
    }

    &--sixth {
        width: 16.66%;
    }

    &--seventh {
        width: 14.28%;
    }

    &--eighth {
        width: 12.5%;
    }

    &--ninth {
        width: 11.11%;
    }

    &--tenth {
        width: 10%;
    }
}

.height {
    &--full {
        height: 100%;
    }

    &--half {
        height: 50%;
    }

    &--third {
        height: 33.33%;
    }

    &--quarter {
        height: 25%;
    }

    &--fifth {
        height: 20%;
    }

    &--sixth {
        height: 16.66%;
    }

    &--seventh {
        height: 14.28%;
    }

    &--eighth {
        height: 12.5%;
    }

    &--ninth {
        height: 11.11%;
    }

    &--tenth {
        height: 10%;
    }
}

.grid {
    &--columns-2 {
        @include grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }

    &--columns-3 {
        @include grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    &--columns-4 {
        @include grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

.flex {
    &--1 {
        flex: 1;
    }

    &--2 {
        flex: 2;
    }

    &--3 {
        flex: 3;
    }

    &--start {
        @include flex-start;
    }

    &--end {
        @include flex-end;
    }

    &--center {
        @include flex-center;
    }

    &--evenly {
        @include flex-evenly;
    }

    &--around {
        @include flex-around;
    }

    &--between {
        @include flex-between;
    }

    &--row {
        flex-direction: row;
    }

    &--column {
        flex-direction: column;
    }

    &--align-start {
        align-items: start;
    }

    &--align-end {
        align-items: end;
    }

    &--align-center {
        align-items: center;
    }
}

.whitespace {
    &--left-xs {
        margin-left: 2px;
    }

    &--left-md {
        margin-left: 5px;
    }

    &--left-lg {
        margin-left: 8px;
    }

    &--left-xlg {
        margin-left: 10px;
    }

    &--right-xs {
        margin-right: 2px;
    }

    &--right-md {
        margin-right: 5px;
    }

    &--right-lg {
        margin-right: 8px;
    }

    &--right-xlg {
        margin-right: 10px;
    }

    &--x-xs {
        margin: 0 2px;
    }

    &--x-md {
        margin: 0 5px;
    }

    &--x-lg {
        margin: 0 8px;
    }

    &--y-lg {
        margin: 2px 0;
    }

    &--y-lg {
        margin: 5px 0;
    }

    &--y-lg {
        margin: 8px 0;
    }
}

.icon {
    &-back-ground-color-blue {
        background-color: $primary !important;
    }

    &-back-ground-color-red {
        background-color: $secondary !important;
    }

    &-finder {
        border: 1px solid red;
    }

    &-move {
        cursor: move;
    }

    &-pointer {
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
            fill: $primary--light;
        }
    }

    &--xxxs {
        height: 11px !important;
        width: 11px !important;
    }

    &--xxs {
        height: 15px;
        width: 15px;
    }

    &--xs {
        height: 20px;
        width: 20px;
    }

    &--md {
        height: 25px;
        width: 25px;
    }

    &--lg {
        height: 30px !important;
        width: 30px !important;
    }

    &--cover {
        width: 100%;
    }

    &--blue-light {
        fill: $primary--light !important;
    }

    &--blue {
        fill: $primary !important;
    }

    &--tertiary--dark {
        fill: $tertiary--dark !important;
    }

    &--tertiary--light {
        fill: $tertiary--light !important;
    }

    &--grey-dark-light {
        fill: $quinary--dark !important;
    }

    &--grey-dark {
        fill: $quinary--xdark !important;
    }

    &--red {
        fill: $secondary !important;
    }

    &--green {
        fill: $quaternary--light !important;
    }

    &--black {
        fill: $black;
    }

    &--white {
        fill: $white;
    }

    &--stroke-1 {
        stroke: .5;
    }

    &--stroke-2 {
        stroke: .8;
    }

    &--stroke-3 {
        stroke: 1.1;
    }
}

.text {
    &--uppercase {
        text-transform: uppercase;
    }

    &--lowercase {
        text-transform: lowercase;

        &.first-letter {
            &--uppercase {
                &:first-letter {
                    text-transform: uppercase;
                }
            }

            &--lowercase {
                &:first-letter {
                    text-transform: lowercase;
                }
            }
        }
    }

    &--capitalize {
        text-transform: capitalize;
    }

    &--bold {
        font-weight: 600;
    }

    &--italic {
        font-style: italic;
    }
}

.font {
    &-size--xxs {
        font-size: .8rem;
    }

    &-size--xs {
        font-size: 1rem;
    }

    &-size--sm {
        font-size: 1.2rem;
    }

    &-size--md {
        font-size: 1.5rem;
    }

    &-size--lg {
        font-size: 2rem;
    }

    &-size--xl {
        font-size: 2.5rem;
    }

    &-size--xxl {
        font-size: 3rem;
    }

    &-color--primary {
        color: $primary !important;
    }

    &-color--primary-light {
        color: $primary--light !important;
    }

    &-color--secondary {
        color: $secondary !important;
    }

    &-color--tertiary {
        color: $tertiary !important;
    }

    &-color--quaternary {
        color: $quaternary !important;
    }

    &-color--quinary {
        color: $quinary !important;
    }
}

.margin {
    &--xxs {
        margin: 2px;
    }

    &--xs {
        margin: 5px;
    }

    &--sm {
        margin: 8px;
    }

    &--md {
        margin: 10px;
    }

    &--lg {
        margin: 15px;
    }

    &--xl {
        margin: 20px;
    }

    &--xxl {
        margin: 25px;
    }

    &-left--xxs {
        margin-left: 2px;
    }

    &-left--xs {
        margin-left: 5px;
    }

    &-left--sm {
        margin-left: 8px;
    }

    &-left--md {
        margin-left: 10px;
    }

    &-left--lg {
        margin-left: 15px;
    }

    &-left--xl {
        margin-left: 20px;
    }

    &-right--xxs {
        margin-right: 2px;
    }

    &-right--xs {
        margin-right: 5px;
    }

    &-right--sm {
        margin-right: 8px;
    }

    &-right--md {
        margin-right: 10px;
    }

    &-right--lg {
        margin-right: 15px;
    }

    &-right--xl {
        margin-right: 20px;
    }

    &-top--xxs {
        margin-top: 2px;
    }

    &-top--xs {
        margin-top: 5px;
    }

    &-top--sm {
        margin-top: 8px;
    }

    &-top--md {
        margin-top: 10px;
    }

    &-top--lg {
        margin-top: 15px;
    }

    &-top--xl {
        margin-top: 20px;
    }

    &-top--xxl {
        margin-top: 30px;
    }

    &-bottom--xxs {
        margin-bottom: 2px;
    }

    &-bottom--xs {
        margin-bottom: 5px;
    }

    &-bottom--sm {
        margin-bottom: 8px;
    }

    &-bottom--md {
        margin-bottom: 10px;
    }

    &-bottom--lg {
        margin-bottom: 15px;
    }

    &-bottom--xl {
        margin-bottom: 20px;
    }

    &-bottom--xxl {
        margin-bottom: 30px;
    }

    &-y--xxs {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    &-y--xs {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &-y--sm {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &-y--md {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &-y--lg {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &-y--xl {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &-y--xxl {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.padding {
    &--xxs {
        padding: 2px;
    }

    &--xs {
        padding: 5px;
    }

    &--sm {
        padding: 8px;
    }

    &--md {
        padding: 10px;
    }

    &--lg {
        padding: 15px;
    }

    &--xl {
        padding: 20px;
    }

    &--xxl {
        padding: 25px;
    }

    &-left--xxs {
        padding-left: 2px;
    }

    &-left--xs {
        padding-left: 5px;
    }

    &-left--sm {
        padding-left: 8px;
    }

    &-left--md {
        padding-left: 10px;
    }

    &-left--lg {
        padding-left: 15px;
    }

    &-left--xl {
        padding-left: 20px;
    }

    &-right--xxs {
        padding-right: 2px;
    }

    &-right--xs {
        padding-right: 5px;
    }

    &-right--sm {
        padding-right: 8px;
    }

    &-right--md {
        padding-right: 10px;
    }

    &-right--lg {
        padding-right: 15px;
    }

    &-right--xl {
        padding-right: 20px;
    }

    &-top--xxs {
        padding-top: 2px;
    }

    &-top--xs {
        padding-top: 5px;
    }

    &-top--sm {
        padding-top: 8px;
    }

    &-top--md {
        padding-top: 10px;
    }

    &-top--lg {
        padding-top: 15px;
    }

    &-top--xl {
        padding-top: 20px;
    }

    &-top--xxl {
        padding-top: 30px;
    }

    &-bottom--xxs {
        padding-bottom: 2px;
    }

    &-bottom--xs {
        padding-bottom: 5px;
    }

    &-bottom--sm {
        padding-bottom: 8px;
    }

    &-bottom--md {
        padding-bottom: 10px;
    }

    &-bottom--lg {
        padding-bottom: 15px;
    }

    &-bottom--xl {
        padding-bottom: 20px;
    }

    &-bottom--xxl {
        padding-bottom: 30px;
    }

    &-y--xxs {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    &-y--xs {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &-y--sm {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &-y--md {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &-y--lg {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-y--xl {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &-y--xxl {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &-x--xxs {
        padding-left: 2px;
        padding-right: 2px;
    }

    &-x--xs {
        padding-left: 5px;
        padding-right: 5px;
    }

    &-x--sm {
        padding-left: 8px;
        padding-right: 8px;
    }

    &-x--md {
        padding-left: 10px;
        padding-right: 10px;
    }

    &-x--lg {
        padding-left: 15px;
        padding-right: 15px;
    }

    &-x--xl {
        padding-left: 20px;
        padding-right: 20px;
    }

    &-x--xxl {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.input {
    &-file__container {
        @include flex-center;
        position: relative;
        border-radius: 7px;
        width: 100%;
        background: $quinary--light;
        border: 2px dotted $quinary;
        border-radius: 7px;
        cursor: pointer;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        transition: 0.2s ease-in-out;
        min-height: 300px;

        &:hover {
            background: darken($color: $quinary--light, $amount: 1);
            border: 2px dotted darken($color: $quinary, $amount: 1);
        }
    }

    &-file-hint__container {
        @include flex-center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    &--no-border {
        border: none;
    }
}

.breadcrum {
    &-item--focus {
        color: $black;
    }

    &-item--unfocus {
        cursor: pointer;
        color: $black;
        transition: 0.2s;

        &:hover {
            color: $black;
        }
    }
}

.file-list__container {
    padding: 20px 0;
    list-style: none;

    >*+* {
        margin-top: 12px;
    }
}

.file-name__container {
    @include flex-between;
    width: 100%;
    transition: 0.2s ease-in-out;
    border-radius: 4px;
    padding: 5px;

    &:hover {
        background-color: $quinary--light;
    }
}

.spin {
    &__container {
        @include flex-center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 9999;

        &.opacity--white {
            background-color: rgba($color: $white, $alpha: 0.8);
        }
    }
}

.list {
    &-unorder__bullet {
        display: inline-block;
        position: relative;
        background-color: $black;
        border-radius: 999rem;
        font-weight: bold;
        width: 16px;
        height: 16px;

        * {
            font-size: .8rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;
        }
    }
}

.button {
    &--red {
        background: $secondary--light !important;
    }

    &--lg-rounded {
        border-radius: 999rem;
    }

    &--md-rounded {
        border-radius: 10px;
    }

    &--sm-rounded {
        border-radius: 5px;
    }

    &--sm {
        cursor: pointer;
        background: $primary--light;
        padding: 5px 8px;
        border: none;
        outline: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: darken($color: $primary--light, $amount: 8);
        }

        * {
            font-weight: 500;
            font-size: .85rem;
            color: $white;
            fill: $white;
        }
    }

    &--md {
        cursor: pointer;
        background: $primary--light;
        padding: 8px 10px;
        border: none;
        outline: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: darken($color: $primary--light, $amount: 8);
        }

        * {
            font-weight: 600;
            font-size: 1.3rem;
            color: $white;
            fill: $white;
        }
    }

    &--lg {
        cursor: pointer;
        background: $primary--light;
        padding: 10px 13px;
        border: none;
        outline: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: darken($color: $primary--light, $amount: 8);
        }

        * {
            font-weight: bold;
            font-size: 2rem;
            color: $white;
            fill: $white;
        }
    }
}

.messenger {
    &__container {
        @include flex-center;
        width: 100%;
        padding: 10px 15px;
        border-radius: 8px;
        color: $white;
    }

    &__message {
        &--error {
            background-color: $secondary--light;
        }

        &--warning {
            background-color: $tertiary--xlight;
        }

        &--success {
            background-color: $quaternary--light;
        }
    }
}

.opacity {
    &--full {
        opacity: 1;
    }

    &--half {
        opacity: 0.5;
    }

    &--quarter {
        opacity: 0.25;
    }

    &--eighth {
        opacity: 0.125;
    }

    &--zero {
        opacity: 0;
    }
}

.line-break {
    &--sm {
        width: 100%;
        height: 1px;
        background-color: $quinary;
    }

    &--md {
        width: 100%;
        height: 2px;
        background-color: $quinary;
    }

    &--lg {
        width: 100%;
        height: 3px;
        background-color: $quinary;
    }
}

.css-6j8wv5-Input {
    padding: 11.5px 14px !important;
}
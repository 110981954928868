.dashboard-wrapper {
    background-color: $quinary--light;
    height: 100%;
    // overflow: hidden;

    .menu-aside__container {
        @include flex-between;
        background-color: $white;
        height: min-content;
        flex-direction: column;
        overflow-y: auto;

        // * Overides MUI styles
        .MuiToggleButton-primary {
            background-color: $primary--light;

            span {
                color: $white;
            }

            svg {
                fill: $white;
            }
        }

        .MuiButtonGroup-grouped {
            &:not(:last-of-type) {
                border: none;
                border-bottom: none !important;
            }
        }
    }

    .menu-aside__item-text {
        color: $quinary--xdark;
        margin-left: 14px;
    }

    .menu-aside__list {
        padding: 0;
        list-style: none;
    }

    .menu-aside__item-focus {
        background: $primary--light;
    }

    .content__container {
        height: 100%;
        display: grid;
        grid-template-columns: minmax(316px, 16.5%) 1fr;
    }

    .top-menu__container {
        @include flex-around;
        position: relative;
        background-color: $white;
        height: fit-content;
        padding: 25px 22px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        box-shadow: 0px 10px 20px 0px rgba($color: $quinary--dark, $alpha: 0.1);
    }

    .top-menu__logo {
        @include flex-start;
    }

    .top-menu__searchbar {
        position: relative;
        margin-right: 13px;

        // TODO: Define display position
        // .search-icon {
        //     position: absolute;
        //     top: 50%;
        //     left: 12px;
        //     transform: translate(0, -50%);
        // }

        input {
            padding-left: 35px;
        }
    }

    .top-menu__user-access {
        @include flex-end;
    }

    .name__container {
        @include flex-end;
        align-items: flex-end;
        flex-direction: column;
        margin: 0 11px;

        .user-name {
            font-size: 1rem;
            display: block;
        }

        .user-rol {
            font-size: 0.85rem;
            display: block;
            color: $quinary--xdark;
        }
    }

    .user-data-name {
        @include flex-around;
    }

    .user-menu-notif {
        position: relative;
        margin-right: 15px;

        .new-msg-alert {
            position: absolute;
            right: 20px;
            top: 5px;
            height: 12px;
            width: 12px;
            border: 2px solid $white;
            border-radius: 999rem;
        }
    }

    // * Overides MUI styles
    .MuiButtonGroup-root {
        margin-top: 20px;
        padding-bottom: 0px;
        padding-top: 30px;
        border-top: 3px solid $quinary--light;
        border-radius: 0;
    }

    // * Content detail
    .content-detail__container {
        //padding: 43px 28px;
        padding: 15px;
        overflow: auto;

        // * Overides MUI styles
        .MuiCard-root {
            @include flex-center;
            flex-direction: column;
            padding: 40px 20px;
            box-shadow: 0px 2px 10px 2px rgba($color: $quinary--xdark, $alpha: 0.1);
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2 ease-in-out;

            &:hover {
                box-shadow: 0px 2px 6px 1px rgba($color: $quinary--xdark, $alpha: 0.2);

                // path,
                h6 {
                    transition: 0.3 ease-in-out;
                    fill: $primary--light;
                    color: $primary--light;
                }
            }
        }

        .detail__container {
            display: grid;
            gap: 14px;
            // grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        }

        .procedure-creation__container {
            background-color: $white;
            border-radius: 10px;
            height: min-content;
            padding-bottom: 50px;
        }

        .contract-details__main {
            border-radius: 10px;
            height: min-content;
        }

        .contract-details__header {
            @include flex-center;
            background-color: #fbfbfb;
            border-radius: 10px;
            height: min-content;
            margin-bottom: 15px;

            .breadcrumbs-navigation {
                padding: 20px;
                border-bottom: 1px solid $quinary--light;
                width: 100%;
            }

            .status-tracker {
                list-style: none;
                padding: none;
                margin: none;
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
                width: 100%;
                padding: 20px;
            }

            .status-tracker-item__container {
                position: relative;

                &::after {
                    content: url("../../assets/icons/chevronGreatThan.svg");
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 80%;
                    transform: translate(0, -50%);
                }
            }

            .status-tracker__icon-container {
                @include flex-center;
                height: 30px;
                width: 30px;
                border-radius: 5px;
                margin-right: 10px;
            }

            .status--approved {
                background-color: $quaternary--xlight;

                svg {
                    fill: $quaternary--dark;
                }
            }

            .status--inprogress {
                background-color: $tertiary--xlight;

                svg {
                    fill: $tertiary;
                }
            }

            .status--notdone {
                background-color: $secondary--xlight;

                svg {
                    fill: $secondary;
                }
            }

            .status--pending {
                background-color: $secondary--xlight;

                svg {
                    fill: $secondary--dark;
                }
            }

            .status-text--approved {
                color: $quaternary--dark;
            }

            .status-text--inprogress {
                color: $tertiary;
            }

            .status-text--pending {
                color: $secondary--dark;
            }
        }

        .contract-detail__info-container {
            width: 100%;
            padding-bottom: 50px;
            margin-bottom: 20px;
            border-bottom: 1px solid $quinary--light;

            .contract-summary__detail {
                padding: 20px;
                background-color: lighten($color: $primary--light, $amount: 50);
                border-radius: 10px;
                border: 1px dashed $quinary--dark;
            }

            .contract-detail__identifier {
                @include flex-center;
                height: 35px;
                width: 35px;
                border-radius: 5px;
                background-color: $primary--light;

                * {
                    color: $white;
                }
            }

            .info-header__title {
                color: $black;
                font-weight: bold;
            }

            .info-header__subtitle {
                color: $quinary--xdark;
            }

            .list-item-name {
                display: block;
                color: $black;
            }
        }

        .contract-details__container {
            @include flex-center;
            padding: 50px 38px;
            background-color: #fbfbfb;
            border-radius: 10px;
            height: min-content;
        }

        .procedure-creation__track-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            border-bottom: 2px solid $quinary--light;

            .procedure-creation__track-item {
                @include flex-start;
                flex-wrap: nowrap !important;
                // position: relative;

                // * Chevron to show next step
                // &:not(:last-child) {
                //     &::after {
                //         content: "";
                //         height: 15px;
                //         width: 15px;
                //         border-top: 2px solid $quinary--dark;
                //         border-right: 2px solid $quinary--dark;
                //         position: absolute;
                //         top: 50%;
                //         right: 20%;
                //         transform: translate(0, -50%) rotate(45deg);
                //     }
                // }
            }

            .procedure-creation-step {
                @include flex-center;
                background-color: #C8C8C8;
                height: 45px;
                width: 45px;
                border-radius: 5px;
            }

            .step--active {
                background-color: $primary--light;
            }

            .procedure-creation-description {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
            }
        }

        .procedure-creation__form-container {
            @include flex-center;
            padding: 50px 10px;

            .form__container {
                width: 90%;
            }

            .form-title__container {
                margin-bottom: 44px;
            }
        }

        .signature-member__form-container {
            margin: 60px 0;
        }
    }

    .cards__container {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
    }

    .contracts-filter__container {
        padding: 36px 38px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 17px;
        background-color: $white;
        border-radius: 10px;
        margin: 24px 0;
    }

    .contracts-status-list__container {
        //padding: 10px 38px;
        padding: 10px;
        background-color: $white;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 10px;
    }

    .contract-datagrid__container {
        height: 500px;
        width: 100%;
    }

    .contracts-filter-form__container {
        @include flex-center;
        background-color: $quinary--light;
        border-radius: 10px;

        >*+* {
            margin-left: 14px;
        }

        // * Overides MUI styles
        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }

    .buttons-menu__status {
        @include flex-center;

        >*+* {
            margin-left: 10px;
        }
    }

    .contracts-list__top-menu {
        @include flex-between;
        padding-bottom: 27px;
        border-bottom: 2px solid $quinary--light;
    }

    .contracts-list__items {
        position: relative;
        margin-top: 34px;

        .list-item--divider {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
            margin-bottom: 30px;
        }

        .list-header {
            background-color: $quinary--light;
            border-radius: 5px;
            padding: 17px 24px;
        }

        .list-item {
            place-items: center;
        }
    }

    .grid__header-item {
        background: $quinary--light;

        * {
            color: $quinary--xdark;
        }
    }

    .grid__row-cell {
        text-align: center !important;
        justify-content: center !important;
    }

    .status__cell {
        @include flex-center;
    }

    .status__container {
        @include flex-center;
        flex-wrap: nowrap;
        border-radius: 999rem;
        padding: 0px 5px;
        font-weight: 500;
        text-align: center !important;
        height: 40px;
        width: 100%;

        &.created {
            color: $quinary--dark;
            background-color: darken($color: $quinary--light, $amount: 2);
        }

        &.pending {
            color: $tertiary--dark;
            background-color: $tertiary--light;
        }

        &.rejected {
            color: $secondary--xlight;
            background-color: $secondary--light;
        }

        &.approved {
            color: $quaternary--dark;
            background-color: $quaternary--light;
        }
    }

    .grid-toolbar {
        svg {
            path {
                fill: $primary--light;
            }
        }
    }

    .grid__row-item {
        &.text--uppercase {
            text-transform: lowercase;

            &:first-letter {
                text-transform: uppercase;
            }

            &.text--uppercase-all {
                text-transform: uppercase;
            }
        }

        &.text--left {
            text-align: left;
        }

        &.text--center {
            text-align: center;
        }

        &.text--right {
            text-align: right;
        }
    }

    .board__container {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;

        &--visible {
            opacity: 1;
            visibility: visible;
        }

        &--invisible {
            opacity: 0;
            visibility: none;
            pointer-events: none;
        }

        &--open {
            right: 0;
        }

        &--close {
            right: -100%;
        }

        .sort-list-container {
            position: relative;
            overflow: hidden;
            overflow-y: auto;
            border-radius: 10px;
            background-color: lighten($color: $primary--light, $amount: 50);
        }

        .sort-list-item {
            cursor: pointer;
            background-color: $white;
            border-radius: 5px;
            border: 1px solid $quinary--light;
            box-shadow: 0 2px 5px rgba($color: $primary--dark, $alpha: 0.2);
            transition: all 0.2s ease-in-out;

            &:hover {
                box-shadow: 0 4px 3px rgba($color: $primary--dark, $alpha: 0.3);
            }

            &.item--active {
                border: 1.3px solid $quinary--dark;
            }
        }

        .sort-list-submit {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }

        .sort-fields__container {
            height: 50vh;
        }
    }

    .notification-banner {
        width: 100%;
        padding: 15px 10px;
        transition: all 0.3s ease-in-out;
        z-index: 9999;
    }

    .notification--show {
        visibility: visible;
        opacity: 1;
        top: 0;
        left: 0;
    }

    .notification--hide {
        visibility: none;
        opacity: 0;
        top: -100%;
        left: 0;
    }

    .notification-banner--success {
        background-color: $quaternary--light;
        color: $quaternary--dark;
    }

    .notification-banner--warning {
        background-color: $tertiary--light;
        color: $tertiary--dark;
    }

    .notification-banner--error {
        background-color: $secondary--light;
        color: $secondary--dark;
    }

    .notification-banner--info {
        background-color: $tertiary--light;
        color: $tertiary--dark;
    }

    .status_tag {
        font-size: 0.8rem;
        padding: 5px 10px;
        border-radius: 999rem;
        width: fit-content;
        font-weight: 600;
    }

    .status--pending {
        background-color: $tertiary--light;
        color: $tertiary--dark;

        * {
            fill: $tertiary--dark;
        }
    }

    .status--rejected {
        background-color: $secondary--light;
        color: $secondary--xdark;

        * {
            fill: $secondary--xdark;
        }
    }

    .status--approved {
        background-color: $quaternary--light;
        color: $quaternary--dark;

        * {
            fill: $quaternary--dark;
        }
    }

    .input-field--xs {
        padding: 5px 0px;
        border: none;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 2px solid $quinary--dark;

        &:focus {
            outline: none;
            border-bottom: 2px solid $primary;
        }
    }

    .signers-payment {
        &--show {
            top: 10px;
            opacity: 1;
            visibility: visible;
        }

        &--hide {
            top: -100%;
            opacity: 0;
            visibility: hidden;
        }

        &__container {
            right: 10px;
            background-color: $white;
            border-radius: 10px;
            padding: 20px 25px;
            box-shadow: 0px 10px 20px 0px rgba($color: $quinary--dark, $alpha: 0.1);
            border: 1px solid $primary--xlight;
            z-index: 9999;
            transition: cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
        }

        &__container--error {
            top: 10px;
            right: 10px;
            background-color: lighten($color: $secondary--light, $amount: 20);
            border-radius: 10px;
            padding: 20px 25px;
            box-shadow: 0px 10px 20px 0px rgba($color: $quinary--dark, $alpha: 0.1);
            border: 1px solid $secondary;
            z-index: 9999;
        }
    }

    .data-grid {
        &__no-rows {
            height: auto;
            position: absolute;
            top: 40%;
            left: 50%;
            padding: 20px 0px;
            text-align: center;
            transform: translate(-50%, 0);
        }
    }

    .portion-payer {
        &__container {
            background-color: $white;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 30px;
            border-radius: 0 4px 4px 0;
            font-size: 0.8rem;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 2.86px;
                left: -6px;
                height: 13.5px;
                width: 13.5px;
                background: linear-gradient(45deg, $white 55%, transparent 75%);
                transform: rotate(45deg);
                border-radius: 2px;
            }
        }
    }
}

.css-1engcz7-MuiDataGrid-root .MuiDataGrid-row:hover,
.css-1engcz7-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
    background-color: $quinary--light !important;
}

.MuiDataGrid-panelWrapper {
    background-color: $white;
    border-radius: 10px;
}

.MuiDataGrid-panelFooter {
    button {
        color: $quinary--xdark;
    }
}

.custom-tooltip {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 2px rgba(116, 124, 145, 0.2);
    padding: 5px;
    text-align: left;

    .label {
        color: $secondary;
    }

    .intro {
        color: $secondary;
    }

    .doc {
        color: $primary;
    }
}

.css-1fagdom-MuiDataGrid-root {
    .MuiDataGrid-cell {
        justify-content: center !important;
    }
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    width: 25%;

    .title-notification {
        font-weight: 600 !important;
        font-size: 1em;
    }

    .date-notification {
        font-weight: 400;
        font-size: 0.8em;
        color: $quinary--xdark;
    }

    .description-notification {
        color: $quinary--xdark;
        font-size: 0.9em;
        text-align: justify;
        font-weight: 400;
        line-height: 1.5em;
        padding-top: 5px;
        padding-left: 25px;
    }
}

.title-menu__status {
    display: flex;
    flex-direction: column;
}